#project-main {
    height: calc(100vh - 176px);
    display: flex;
    justify-content: center;
    align-items: center;    
}
@media (max-height: 640px) {
    #project-main{ height: 640px;}
}

.project-main-content {
    flex-basis: 100%;
    position: relative;
    text-align: center;
    z-index: 0;
}  
    #project-main-title {
        font-size: 80px;
        letter-spacing: 3px;
    }
    #project-main-subtitle {
        letter-spacing: 2px;
        margin-top: 16px;
        font-family: 'Roboto';
        font-size: 18px;
        color: gray;
    }
    .project-main-linebr {
        margin: 32px auto;
        width: 100px;
        color: whitesmoke;
    }
    #project-main-des {
        margin: 16px auto 0;
        font-family: 'Roboto';
        font-size: 16px;
        width: 800px;
        line-height: 22px;
        color: lightgrey;
    }

.project-section {
    height: 100vh;
    min-width: 1200px;
    min-height: 760px;
}
@media (max-height: 760px) {
    .project-section{ height: 760px;}
}
    .project-section-title {
        margin: 0 26px;
        font-size: 56px;
        display: inline-block; 
        color: black;
    }

    .project-section-line {
        height: 4px;
        background-color: black; 
        border: none;
        display: inline-block;
        margin-bottom: 16px;
        width: 10%
    }
    .project-section-title:before, :after{
        border-bottom: 2px solid #000; 
    }


/* Styling for latest project section 
/////////////////////////////////////*/
#latest-project-content {
    height: calc(97vh - 56px);
    min-height: 664px;
    display: flex;
    justify-content: center;
    align-items: center;    
}

#latest-project-content h2, #latest-project-content h3,  #latest-project-content p { color: black }

/* Styling for all projects section (gallery)
/////////////////////////////////////*/
#all-projects-content {
    height: auto;
    width: 72vw;
    min-width: 1180px;
    padding: 0 0px;
    margin: 16vh auto 0;
}

#all-projects-title {
    font-size: 80px;
    letter-spacing: 3px;
}

#all-projects-subtitle {
    letter-spacing: 1px;
    margin-top: 12px;
    font-family: 'Roboto';
    font-size: 16px;
    color: gray;
}

#all-projects-grid {
    width: 57vw;
    margin: 0 auto;
    padding-bottom: 16vh;
}

.all-projects-row {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
}

.all-projects-entry {
    flex-basis: 33.3333%;
    height: 24vh;
    position: relative;
    background-color: white;
    cursor: pointer;
    transition: 0.3s;
}

    @media (max-height: 250px) {
        .all-projects-entry{ height: 72vh;}
    }
    .all-projects-entry:hover img{
        opacity: 0.56;
    }
    .all-projects-entry-selected {
        position: absolute;
        font-size: 2.5vw;
        top: 8.4vh;
        z-index: 1;
        width: 19vw;
    }
    @media (max-height: 250px) {
        .all-projects-entry-selected{ top: 22vh;}
    }
        .all-projects-entry-selected * {
            color: black;
            letter-spacing: 1px;
        }
        
        .all-projects-entry-selected > p {
            font-size: 0.92vw;
        }
        @media (max-width: 648px) {
            .all-projects-entry-selected{ 
                font-size: 10vw;
                width: 76vw;
            }
            .all-projects-entry-selected > p {
                font-size: 3.68vw;
            }
        }


/* Styling for popup when project entry is clicked */
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}


.box {
    position: relative;
    width: 80vw;
    z-index: 20;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: 10vh;
    overflow: auto;
    padding: 2vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
    @media (max-width: 500px) {
        .box{ width: 80%; max-height: none; height: 40%}
    }
    .box p, .box h2, .box h3 {
        color: black;
    }

/*Styling for project description/showcase 
/////////////////////////////////////////*/

.project-title {
    font-size: 56px;
    letter-spacing: 1px;
    font-family: 'Roboto';
    text-align: center;
}

.project-stack {
    margin-top: 16px;
    font-size: 18px;
    letter-spacing: 0.5px;
    font-family: 'Roboto';
    text-align: center;
}

.project-left-section {
    width: 48%;
    margin-right: 2%;
    display: inline-block;
    margin-top: 3vh;
    vertical-align: top;
}
    .project-left-section > * { z-index: 0; }
    .img-gallery {
        width: 92%;
        margin-bottom: 30px;
    }


.project-right-section {
    width: 48%;
    height: 100%;
    margin-left: 2%;
    display: inline-block;
    margin-top: 3vh;
}

    .project-right-section > p {
        font-size: 16px;
        line-height: 18px;
    }

.project-date {
    color: black;
    font-weight: bold;
    display: inline-block;
}
.project-link {
    border-radius: 0.25rem;
    background-color: lightgray;
    padding: 8px;
    margin-right: 16px;
    transition: 0.3s;
    border: none;
    cursor: pointer;
}
    .project-link:hover{
        opacity: 0.6;
    }
    .project-link * {
        color: black
    }
    .project-link > h3 {
        margin-left: 6px;
        font-size: 16px;
        letter-spacing: 1px;
    }

/*Mobile Responsive Design */
@media (max-width: 648px) {
    #project-main {
        display: block;
        height: auto;
    }
    .project-main-content { padding: 36px 10px; }
    #project-main-des { width: auto; }

    .project-section {
        width: 100%;
        height: auto;
        min-width: 0;
        min-height: 0;
        padding-bottom: 32px;
    }
    .project-section-title {
        text-align: center;
        display: block;
        margin-bottom: 48px;
    }
    .project-section-line { display: none; }

    #latest-project-content {
        height: auto;
        min-height: 0;
        display: block;
        padding: 0 16px;
    }
    .project-left-section {
        width: 100%;
        display: block;
    }
    .project-right-section {
        width: 100%;
        display: block;
        margin-left: 0;
        margin-bottom: 3vh;
    }

    .project-title {
        font-size: 40px;
        margin-top: 3vh
    }
    .project-date {
        line-height: 40px;
    }

    #all-projects-content {
        min-width: 0;
        width: 100%;
        padding: 0 16px;
        margin-top: 8vh
    }
        #all-projects-content hr { width: 100% !important; }

    #all-projects-grid { width: 80%; margin-top: 4vh; padding-bottom: 8vh; }
    .all-projects-row { flex-direction: column; }
    .all-projects-entry { height: 44vw; }
    .box { height: 76vh; }
}