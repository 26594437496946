#home-main {
    height: calc(100vh - 230px);
    display: flex;
    justify-content: center;
    align-items: center;    
}

@media (max-height: 648px) {
    #home-main{ height: 720px;}
}

.home-main-content {
    flex-basis: 50%;
    padding-top: 40px;
    position: relative;
    z-index: 0;
}
.home-main-content:first-of-type { padding-left: 20px; }

    /* Left Side Content */
    .home-main-title {
        font-size: 88px;
    }

    .home-main-subtitle {
        letter-spacing: 1px;
        font-family: 'roboto';
        font-size: 32px;
        color: grey;
        max-width: 450px;
    }

    .home-main-subsubtitle {
        font-size: 16px;
        text-decoration: underline;
    }
        .home-main-subsubtitle { transition: 0.3s; }
        .home-main-subsubtitle a:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    

    /* Right Side Content */
    .home-main-pic {
        width: 380px;
        height: 380px;
        margin-bottom: 140px;
        margin-left: 100px;
        border-radius: 50%;
    }
    
    .home-main-circ {
        top: 25%;
        left: 40%;
        margin-top: 40px;
        border-radius: 50%;
        width: 320px;
        height: 320px;    
        position: absolute;
        z-index: -1;
    }
    .circ-dark {
        background-color: violet;
    }
    .circ-light {
        background-color: #FFD73e;
    }

    .home-main-subcirc {
        top: 4%;
        left: 7%;
        border-radius: 50%;
        width: 280px;
        height: 280px;    
        position: absolute;
        z-index: -1;
    }
    .subcirc-dark {
        background-color: lavender;
        border: 20px solid plum;
    }
    .subcirc-light {
        background-color: cornsilk;
        border: 20px solid #FFdb8e;
    }


/*Mobile Responsive Design */
@media (max-width: 648px) {
    #home-main {
        height: auto;
        flex-direction: column-reverse;
    }
    .home-main-content {
        padding: 24px 16px 0 !important;
    }
    .home-main-title { font-size: 80px;}
    .home-main-subtitle {font-size: 24px;}
    .home-main-subsubtitle { font-size: 12x; }

    /* Right Side Content */
    .home-main-pic {
        width: 72vw;
        height: 72vw;
        margin: 24px 0 0;
        border-radius: 50%;
    }
    

    .home-main-circ {
        display: none;
    }
}