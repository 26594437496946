#about-main {
    height: calc(100vh - 176px);
    display: flex;
    justify-content: center;
    align-items: center;    
}

@media (max-height: 720px) {
    #about-main{ height: 700px;}
}

.about-main-content {
    flex-basis: 50%;
    position: relative;
    z-index: 0;
}

    /*Left Side Content */
    .about-main-pic{
        margin-right: 40px;
        margin-bottom: 2vh;
        width:360px;
        height: auto;
    }

    /*Right Side Content */
    .about-main-title {
        letter-spacing: 2px;
        font-size: 68px;
    }

    .about-main-linebreak {
        width: 100px;
        margin: 20px 0;

    }

    .about-main-paragraph {
        width: 500px;
        letter-spacing: 1px;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 12px;
    }
        .about-main-content > a {
            text-decoration: underline;
            font-weight: 900;
        }

    .about-main-socials {
        margin-bottom: 2vh;
        font-size: 42px;
    }

    .about-main-socials > *  {
        margin-right: 20px;
        cursor: pointer;
        transition: 0.5s;
    }
    
    .about-main-socials > *:hover  {
        opacity: 0.3;
    }

    .about-main-socials svg {
        color: black;
    }

/*Mobile Responsive Design */
@media (max-width: 648px) {
    #about-main {
        height: auto;
        flex-direction: column-reverse;
    }
    
    .about-main-content {
        padding: 48px 16px 0 24px;
    }
    .about-main-content > div { margin-left: 0 !important; }
    .about-main-paragraph {
        width: 100%;
    }
    .about-main-socials {
        font-size: 36px;
    }
    .about-main-socials > * {margin-right: 16px; line-height: 20px;}
    .about-main-socials > *:last-of-type { margin-right: 0;}
    .about-main-pic{
        margin-bottom: 4vh;
        margin-right: 0;
        padding-right: 8px;
        width: 100%;
    }
}