/*//////////////////////////////////
Styling for image gallery library 
//////////////////////////////////*/

/* CSS */
@import "~react-image-gallery/styles/css/image-gallery.css";

/*/////////////////////
//General Tag's Styling 
/////////////////////*/
* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  transition: 0.6s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', cursive; 
  letter-spacing: 2px;
}

header {
  position: relative;
  z-index: 1000;
}

a {
  text-decoration: none;
}

u {
  text-decoration: underline;
  display: inline;
}

b {
  font-weight: 900;
  display: inline;
}


/*////////////////////////////
General styling for all pages
////////////////////////////*/
#content-container {
  height: 100%;
  z-index: -3;
}

.content-container-alt {
  height: 100vh;
  z-index: -3;
}

.width-size {
  padding: 0 0px;
  width: 1180px;
  margin: 0 auto;
}

.bottom-pad-med {
  padding-bottom: 8vh;
}

.center {
  text-align: center;
}

#footer-info {
  letter-spacing: 2px;
  width: 1180px;
  margin: 0 auto;
  font-size: 14px;
  padding-bottom: 12px;
  text-align: center;
}
#copyright {
  letter-spacing: 2px;
  width: 1180px;
  margin: 0 auto;
  font-size: 12px;
  padding-bottom: 16px;
  text-align: center;
}

@media (max-width: 648px) {
  .width-size { width: 100% }
  #copyright { width: 100%; }
  #footer-info { width: 100%; margin-top: 24px;}
}

/*//////////////////////////
General styling for icons
//////////////////////////*/
.scroll-down-icon {
  margin-top: 16px;
  font-size: 40px;
  transition: 0.3s;
  cursor: pointer;
}
  .scroll-down-icon:hover {
    opacity: 0.4;
  }

/*/////////
Animations 
//////////*/
.fade-in-2 {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.fade-in-3 {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}

.fade-in-4 {
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}


.fade-in-6 {
  animation: fadeIn ease 6s;
  -webkit-animation: fadeIn ease 6s;
  -moz-animation: fadeIn ease 6s;
  -o-animation: fadeIn ease 6s;
  -ms-animation: fadeIn ease 6s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.float-white {
  animation: float-white 6s ease-in-out infinite;
}

@keyframes float-white {
  0% {
      transform: translatey(0px);
      box-shadow: 0 5px 15px 0px rgba(255,255,255,0.6);
  }
  50% {
      
  box-shadow: 0 25px 15px 0px rgba(255,255,255,0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 5px 15px 0px rgba(255,255,255,0.6);
      transform: translatey(0px);
  }
}

.float-black {
  animation: float-black 6s ease-in-out infinite;
}

@keyframes float-black {
  0% {
      transform: translatey(0px);
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1);
  }
  50% {
      
  box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.8);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1);
      transform: translatey(0px);
  }
}

/* Zoom animation */
.animate {
  -webkit-animation: animatezoom 0.8s;
  animation: animatezoom 0.4s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)}
  to {-webkit-transform: scale(1)}
}

@keyframes animatezoom {
  from {transform: scale(0)}
  to {transform: scale(1)}
}

/*//////////// 
Random styling
////////////*/
.invisible {
  display: none;
}

.black-overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.3);
}

.white-overlay {  
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.56;
  width: 100%;
  height: 100%;
  z-index: 5
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}


/* Main Colors */
.black {
  color: black !important;
}
.bg-black {
  background-color: black !important;
}

.white {color: white !important;}
.bg-white {
  background-color: white !important;
}

.gray {
  color: #ccc !important;
}