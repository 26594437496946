/*//////////////////////
Navigation bar styling 
//////////////////////*/

#portfolio-logo {
  margin-top: 19px;
  margin-right: 4px;
  width: 80px;
  height: auto;
  vertical-align: middle;
}

#navbar-container {
  padding-top: 32px;
  z-index: -5;
}

#navbar-contents {
  font-size: 36px;
  font-family: "Bebas Neue", cursive;
  padding: 0 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navbar-contents * {
  text-decoration: none;
}
#navbar-contents > * {
  width: 200px;
  letter-spacing: 1px;
  display: inline;
  margin: 0 auto;
}

#navbar-contents a {
  transition: 0.3s;
}
#navbar-contents a:hover {
  cursor: pointer;
  opacity: 0.5;
}

#logo {
  vertical-align: middle;
  display: inline-block;
  width: 100px;
  height: 100px;
  font-size: 60px;
  margin: 0 48px;
  z-index: -6;
  border-radius: 50%;
}

/*Mobile Responsive Design */
#navbar-menu-button {
  z-index: 10;
  display: none;
  position: fixed;
  left: 24px;
  top: 24px;
  height: 24px;
  width: auto;
}

#navbar-menu-content {
  display: flex;
  position: fixed;
  width: 80vw;
  height: 100vh;
  top: 0;
  left: -80%;
  opacity: 0;
  background: #101010;
  z-index: 7;
  padding: 100px 0 0 24px;
  text-align: left;
  transition: all 1.2s ease;
  flex-direction: column;
}

#navbar-menu-content > ul {
  font-size: 20px;
  font-family: "Bebas Neue";
  margin: 16px 0;
  letter-spacing: 2px;
}
#navbar-menu-content > hr {
  width: calc(100% - 24px);
  margin: 0;
  color: gray;
  background: gray;
  border-color: rgba(255, 255, 255, 0.25);
}

@media (max-width: 648px) {
  #navbar-container {
    padding: 0;
    padding-top: 16px;
    position: relative;
    height: 72px;
    width: 100%;
  }
  #navbar-contents {
    display: block;
    padding: 0;
  }
  #navbar-contents > * {
    display: none;
    width: auto;
  }
  #navbar-contents > * > a {
    margin: 0;
  }
  #navbar-contents > *:nth-of-type(3) {
    display: inline-block;
  }
  #logo {
    width: 72px;
    height: 72px;
  }
  #portfolio-logo {
    width: 56px;
    margin-top: 14px;
  }

  #navbar-menu-button {
    display: inline;
  }
  #navbar-menu-content.active {
    left: 0;
    opacity: 1;
    transition: all 1s ease;
  }
}

/*//////////////////////////
COMPONENT: DLModeBtn Styling
//////////////////////////*/
.dl-icon {
  height: 24px;
  width: 24px;
}
.dl-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 4px;
}

/* Hide default HTML checkbox */
.dl-switch .dl-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.dl-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.dl-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.dl-input:checked + .dl-slider {
  background-color: white;
}
.dl-input:checked + .dl-slider::before {
  background-color: black;
}

.dl-input:focus + .dl-slider {
  box-shadow: 0 0 1px #ccc;
}

.dl-input:checked + .dl-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.dl-slider.round {
  border-radius: 34px;
}

.dl-slider.round:before {
  border-radius: 50%;
}
/*Mobile Responsive Design */
@media (max-width: 648px) {
  #dl-container {
    position: absolute;
    right: 24px;
    top: 36px;
  }
  .dl-switch {
    margin: 0;
  }
  .dl-icon {
    display: none;
  }
}
