#hire-main {
    height: calc(100vh - 176px);
    display: flex;
    justify-content: center;
    align-items: center;    
}
@media (max-height: 648px) {
    #hire-main{ height: 720px;}
}

.hire-main-content {
    flex-basis: 100%;
    text-align: left;
    position: relative;
    line-height: 32px;
    padding-left: 20px;
    color: gray;
}
    .hire-main-content * {
        font-family: 'Roboto';
    }

#hire-name {
    font-family: 'Bebas Neue';
    font-size: 56px;
    letter-spacing: 2px;
    line-height: 56px;
}

#hire-name ~ h3 {font-size: 16px;}

#hire-advertise {
    font-size: 18px;
    width: 560px;
    color: gray
}

#hire-advertise ~ h4 *, #hire-advertise ~ h4 {
    color: white;
    font-size: 16px;
    font-weight: 900;
}

.hire-social {
    font-size: 32px;
    margin-right: 16px;
    transition: 0.3s;
}

.hire-social:hover  { opacity: 0.3; }

#hire-vector-pic {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 16vw;
    width: auto;
    height: 76vh;
}

@media (max-width: 1240px) {
    #hire-main { height: calc(720px + 76vh);}
    #hire-vector-pic{ position: static;}
}

/*Mobile Responsive Design */
@media (max-width: 648px) {
    #hire-main {
        height: auto;
        display: block;
        padding: 36px 10px 0;
    }

    .hire-main-content {
        padding-left: 14px;
    }
    #hire-advertise {
        width: 100%;
    }

    #hire-vector-pic {
        width: 100%;
        height: auto;
        margin-right: 0;
    }
}